$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$menu-background: $white;

$foreground: #001841;
$primary: #37a006 !default;
$secondary: #ecb807 !default;
$thfont: "Rock Salt", "Times New Roman", "Times", serif !default;

$img-opacity: 0.75;

$simple-line-font-path: "../../node_modules/simple-line-icons/fonts/" !default;
$pswp__assets-path: "../../node_modules/photoswipe/src/css/default-skin/";
