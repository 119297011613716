@import "_variables";
@import "photoswipe/src/css/main";
@import "photoswipe/src/css/default-skin/default-skin";

.gallery-items {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;

  .gallery-item {
    img {
      opacity: $img-opacity;
      width: 10em;
    }

    figcaption {
      display: none;
    }
  }
}

.pswp {
  /* stylelint-disable selector-class-pattern */
  .pswp__caption__center {
    text-align: center;
  }
  /* stylelint-enable selector-class-pattern */
}
